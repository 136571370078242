import {
  Grid,
  GridItem,
  Link,
  LinkKind,
  PhosphorIcon,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { Color } from 'design-system/data';

import formulaVerificationIcon from 'assets/illustrations/target.svg';
import ingredientScreenerIcon from 'assets/illustrations/checklist.svg';
import ingredientFinderIcon from 'assets/illustrations/magnifying-glass.svg';
import { PATHS } from 'constants/index';
import styles from './portal-selection.module.scss';

const WorkbenchSection = () => {
  return (
    <div className={styles.workbench}>
      <Grid>
        <GridItem span={12} responsiveSpan={{ tablet: 12, desktop: 12 }}>
          <div className={styles.header}>
            <Text kind={TextKind.DisplayXSMedium} element={TextElement.H2}>
              Novi tools
            </Text>
            <Text
              kind={TextKind.TextMD}
              element={TextElement.P}
              color={Color.Neutral600}
            >
              Novi offers a variety of tools to help you explore and meet
              leading standards for retailers, regulatory bodies, and the
              scientific community outside of your programs.
            </Text>
          </div>
        </GridItem>
      </Grid>

      <Grid>
        <GridItem
          span={4}
          responsiveSpan={{ mobile: 4, tablet: 4, desktop: 4 }}
        >
          <Link
            kind={LinkKind.Internal}
            to={PATHS.projectDashboard}
            className={styles.tool}
          >
            <img src={formulaVerificationIcon} alt="Formula Workbench" />
            <div className={styles.content}>
              <div>
                <Text
                  kind={TextKind.TextMDMedium}
                  element={TextElement.H3}
                  color={Color.Neutral900}
                >
                  Formula Workbench
                </Text>
                <Text
                  kind={TextKind.TextMD}
                  element={TextElement.P}
                  color={Color.Neutral500}
                >
                  See how your formulas stack up against top industry policies
                </Text>
              </div>
            </div>
            <div className={styles.arrow}>
              <PhosphorIcon iconName="ArrowRight" size="1.5rem" />
            </div>
          </Link>
        </GridItem>
        <GridItem
          span={4}
          responsiveSpan={{ mobile: 4, tablet: 4, desktop: 4 }}
        >
          <Link
            kind={LinkKind.Internal}
            to={PATHS.ingredient_search}
            className={styles.tool}
          >
            <img src={ingredientScreenerIcon} alt="Ingredient Screener" />
            <div className={styles.content}>
              <div>
                <Text
                  kind={TextKind.TextMDMedium}
                  element={TextElement.H3}
                  color={Color.Neutral900}
                >
                  Ingredient Screener
                </Text>
                <Text
                  kind={TextKind.TextMD}
                  element={TextElement.P}
                  color={Color.Neutral500}
                >
                  Screen ingredients and learn what passes industry standards
                </Text>
              </div>
            </div>
            <div className={styles.arrow}>
              <PhosphorIcon iconName="ArrowRight" size="1.5rem" />
            </div>
          </Link>
        </GridItem>
        <GridItem
          span={4}
          responsiveOffset={{ mobile: 0, tablet: 3, desktop: 9 }}
          responsiveSpan={{ mobile: 4, tablet: 4, desktop: 4 }}
        >
          <Link
            kind={LinkKind.Internal}
            to={PATHS.product_marketplace}
            className={styles.tool}
          >
            <img src={ingredientFinderIcon} alt="Material Finder" />
            <div className={styles.content}>
              <div>
                <Text
                  kind={TextKind.TextMDMedium}
                  element={TextElement.H3}
                  color={Color.Neutral900}
                >
                  Material Finder
                </Text>
                <Text
                  kind={TextKind.TextMD}
                  element={TextElement.P}
                  color={Color.Neutral500}
                >
                  Discover materials that are better for people and planet
                </Text>
              </div>
            </div>
            <div className={styles.arrow}>
              <PhosphorIcon iconName="ArrowRight" size="1.5rem" />
            </div>
          </Link>
        </GridItem>
      </Grid>
    </div>
  );
};

export default WorkbenchSection;
