import { useState, useRef, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
  Checkbox,
  InputV2,
  Text,
  TextKind,
  TextElement,
  PhosphorIcon,
} from 'design-system/components';
import styles from './autocomplete.module.scss';
import { MultiSelectDisplay } from './constants';

const Autocomplete = ({
  label = '',
  options = [],
  onSelect = () => null,
  onChange = () => null,
  maxDropdownHeight = '200px',
  placeholder = '',
  autocompleteStyle = {},
  multiSelect = false,
  multiSelectDisplay = '',
  selectedCountName = '',
  onEnter = () => null,
  forceChoice = false,
  value = '',
  inputName = '',
  inputId = 'input-field',
  maxLength = undefined,
}) => {
  const [search, setSearch] = useState(value);
  const [multiSelected, setMultiSelected] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownStyles, setDropdownStyles] = useState({});
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const inputRef = useRef(null);
  const inputFieldRef = useRef(null);
  const dropdownRef = useRef(null);

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleOptionSelect = (option, idx) => {
    onSelect(option);
    if (idx === -2) {
      idx = multiSelected.findIndex((selected) => selected === option.name);
    }
    if (multiSelect) {
      if (inputFieldRef.current) inputFieldRef.current.focus();
      if (idx > -1) {
        handleMultiSelectRemoval(idx);
      } else {
        setMultiSelected([...multiSelected, option.name]);
      }
    } else {
      setSearch(option.name);
      setDropdownOpen(false);
    }
  };

  const handleMultiSelectRemoval = (idx) => {
    const selectedWithRemoval = [
      ...multiSelected.slice(0, idx),
      ...multiSelected.slice(idx + 1),
    ];
    setMultiSelected(selectedWithRemoval);
  };

  const getMultiSelectPills = () => {
    if (multiSelected.length < 1) return null;

    return (
      <>
        {multiSelected.map((selected, idx) => {
          return (
            <div key={idx} className={styles.pill}>
              <Text kind={TextKind.TextSM} element={TextElement.Span}>
                {selected}
              </Text>
              <div
                className={styles['pill-icon-wrapper']}
                onClick={() => handleMultiSelectRemoval(idx)}
              >
                <PhosphorIcon iconName="X" />
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const selectedDisplay = useMemo(() => {
    switch (multiSelectDisplay) {
      case MultiSelectDisplay.PILLS:
        return getMultiSelectPills();
      case MultiSelectDisplay.COMMAS:
        return multiSelected.join(', ');
      case MultiSelectDisplay.COUNT:
        return `${multiSelected.length}${` ${selectedCountName}`} selected`;
      default:
        return '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiSelected, multiSelectDisplay]);

  const handleSearch = (value) => {
    setDropdownOpen(true);
    setSearch(value);
    onChange(value);
  };

  const dropdownVisible = dropdownOpen && search;
  const dropdownId = `dropdown-${label}`;
  const dropdownElement = document.getElementById(dropdownId);

  useEffect(() => {
    if (inputRef.current && dropdownVisible) {
      const { top, left, height, width, bottom } =
        inputRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - bottom;
      let relativePositioning = {};
      if (spaceBelow >= dropdownElement.clientHeight) {
        relativePositioning = { top: `${top + height + window.scrollY}px` };
      } else {
        relativePositioning = {
          bottom: `${spaceBelow + height - window.scrollY}px`,
        };
      }
      if (dropdownElement) {
        setDropdownStyles({
          ...relativePositioning,
          position: 'absolute',
          left: `${left}px`,
          width: width,
          maxHeight: maxDropdownHeight,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownVisible, dropdownElement, inputRef]);

  const handleBlur = (target) => {
    if (
      target?.id !== dropdownId &&
      target?.role !== 'checkbox' &&
      target?.id !== inputId
    ) {
      if (forceChoice) setSearch('');
      setDropdownOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    let newIndex = highlightedIndex;
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        newIndex = Math.min(highlightedIndex + 1, filteredOptions.length - 1);
        setHighlightedIndex(newIndex);
        break;
      case 'ArrowUp':
        event.preventDefault();
        newIndex = Math.max(highlightedIndex - 1, 0);
        setHighlightedIndex(newIndex);
        break;
      case 'Enter':
        if (highlightedIndex > -1) {
          handleOptionSelect(filteredOptions[highlightedIndex], -2);
        } else {
          onEnter();
          setDropdownOpen(false);
        }
        break;
      default:
        return;
    }

    if (
      dropdownRef.current &&
      (event.key === 'ArrowDown' || event.key === 'ArrowUp')
    ) {
      const itemHeight = dropdownRef.current.firstChild.offsetHeight;
      const scrollPosition = itemHeight * newIndex;
      dropdownRef.current.scrollTop = scrollPosition;
    }
  };

  useEffect(() => {
    if (!dropdownOpen) {
      setHighlightedIndex(-1);
    }
  }, [dropdownOpen]);

  return (
    <div className={styles.autocomplete} style={autocompleteStyle}>
      <div ref={inputRef}>
        <InputV2
          id={inputId}
          leadIconName={'MagnifyingGlass'}
          value={search}
          label={label}
          onChange={(e) => handleSearch(e.target.value)}
          onBlur={(e) => handleBlur(e.relatedTarget)}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          leadChildren={selectedDisplay}
          ref={inputFieldRef}
          name={inputName}
          maxLength={maxLength}
        />
      </div>
      {ReactDOM.createPortal(
        <div
          tabIndex={0}
          id={dropdownId}
          className={`${styles.dropdown} ${
            dropdownVisible
              ? styles['dropdown-visible']
              : styles['dropdown-hidden']
          }`}
          style={dropdownStyles}
          ref={dropdownRef}
          onBlur={(e) => (multiSelect ? handleBlur(e.relatedTarget) : null)}
        >
          {filteredOptions.length < 1 && (
            <div className={styles['no-options']}>
              <Text kind={TextKind.TextMD} element={TextElement.Span}>
                No options
              </Text>
            </div>
          )}
          {filteredOptions.map((option, idx) => {
            const multiSelectIdx = multiSelected.findIndex(
              (selected) => selected === option.name
            );
            return (
              <div
                className={`${styles.option} ${
                  idx === highlightedIndex ? styles.highlighted : ''
                }`}
                onClick={() => handleOptionSelect(option, multiSelectIdx)}
                key={idx}
              >
                {multiSelect && <Checkbox isActive={multiSelectIdx > -1} />}
                <Text kind={TextKind.TextMD} element={TextElement.Span}>
                  {option.name}
                </Text>
              </div>
            );
          })}
        </div>,
        document.body
      )}
    </div>
  );
};

Autocomplete.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  maxDropdownHeight: PropTypes.string,
  placeholder: PropTypes.string,
  autocompleteStyles: PropTypes.object,
  multiSelect: PropTypes.bool,
  multiSelectDisplay: PropTypes.oneOf(Object.values(MultiSelectDisplay)),
  selectedCountName: PropTypes.string,
  onEnter: PropTypes.func,
  forceChoice: PropTypes.bool,
  value: PropTypes.string,
  inputId: PropTypes.string,
  inputName: PropTypes.string,
  maxLength: PropTypes.number,
};

export default Autocomplete;
