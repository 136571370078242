import { useFDAProductsContext } from '../Context';
import {
  Text,
  TextKind,
  TextElement,
  Modal,
  WarningRings,
  Button,
  ModalType,
  ButtonKind,
} from 'design-system/components';
import styles from './import-dialog.module.scss';
import { Color } from 'design-system/data';

export const DeleteConfirmationDialog = () => {
  const { deleteProduct, setDeleteConfirmationOpen, deleteConfimationOpen } =
    useFDAProductsContext();

  return (
    <Modal
      show={deleteConfimationOpen}
      type={ModalType.Small}
      onClick={() => setDeleteConfirmationOpen(false)}
    >
      <WarningRings />
      <div className={styles['confirmation-message']}>
        <Text kind={TextKind.DisplayXSMedium} element={TextElement.H2}>
          Delete SKU
        </Text>
        <Text
          color={Color.Neutral600}
          kind={TextKind.TextSM}
          element={TextElement.P}
        >
          The MoCRA portal is separate from all other portals in Novi Connect.
          If you delete this SKU from this portal, you will still be able to
          access it in the Formula Workbench dashboard and any retailer portals
          it has been uploaded to.{' '}
        </Text>
      </div>
      <div className={styles.actions}>
        <Button
          kind={ButtonKind.Tertiary}
          onClick={() => setDeleteConfirmationOpen(false)}
        >
          Cancel
        </Button>
        <Button onClick={() => deleteProduct()}>Delete</Button>
      </div>
    </Modal>
  );
};
